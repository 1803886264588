// Signup.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Argon2, Argon2Mode } from '@sphereon/isomorphic-argon2';
import SlideIn from '../portal/slidin'; // Ensure this path is correct

// Reading Themes from LocalStorage
const themes = {
  light: {
    backgroundColor: '#f5f5f5',
    textColor: '#555',
    formBackgroundColor: '#fff',
    actionButtonBackgroundColor: '#705697',
    sliderButtonBackgroundColor: '#f0f0f0',
    linkColor: '#7144CE',
    spinnerBorderColor: '#3f2d7e',
  },
  dark: {
    backgroundColor: '#2e3440',
    textColor: '#d8dee9',
    formBackgroundColor: '#3b4252',
    actionButtonBackgroundColor: '#9673d3',
    sliderButtonBackgroundColor: '#434c5e',
    linkColor: '#9673d3',
    spinnerBorderColor: '#5a5475',
  },
};

// Styles Object (dynamically modified)
const useStyles = (theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 180px)',
    padding: '2rem',
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  },
  formContainer: {
    width: '100%',
    maxWidth: '500px',
    backgroundColor: theme.formBackgroundColor,
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    color: theme.textColor,
  },
  input: {
    width: 'calc(100% - 25px)',
    padding: '0.75rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    outline: 'none',
    backgroundColor: theme.formBackgroundColor,
    color: theme.textColor,
  },
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    marginBottom: '1rem',
  },
  sliderButton: {
    flex: 1,
    padding: '0.5rem',
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.textColor,
    fontFamily: 'Baloo 2',
    fontSize: '18px',
    fontWeight: '500',
    backgroundColor: theme.sliderButtonBackgroundColor,
    borderRadius: '4px',
  },
  actionButton: {
    padding: '0.5rem 1rem',
    backgroundColor: theme.actionButtonBackgroundColor,
    color: '#f1f1f1',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontFamily: '"Baloo 2", sans-serif',
    lineHeight: '1',
    cursor: 'pointer',
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
  },
  linkText: {
    marginTop: '10px',
    cursor: 'pointer',
    color: theme.linkColor,
    fontFamily: 'Baloo 2',
    textAlign: 'center',
  },
});

// Spinner Component for loading states
const Spinner = ({ theme }) => (
  <div
    className="spinner"
    style={{
      border: '4px solid #f3f3f3',
      borderTop: `4px solid ${theme.spinnerBorderColor}`,
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      animation: 'spin 1s linear infinite',
      marginRight: '10px',
    }}
  ></div>
);

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTheme = localStorage.getItem('theme') || 'light';
  const theme = themes[currentTheme];
  const styles = useStyles(theme);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLogin, setIsLogin] = useState(false); // Toggle between Sign Up and Log In
  const [isResetPassword, setIsResetPassword] = useState(false); // Show Reset Password form
  const [resetToken, setResetToken] = useState('');
  const [resetButtonDisabled, setResetButtonDisabled] = useState(false);
  const [resetButtonText, setResetButtonText] = useState('Send Request');
  const resetButtonTimeoutRef = useRef(null);
  const [slideInConfig, setSlideInConfig] = useState({
    isOpen: false,
    type: 'alert', // 'alert' or 'captcha'
    title: '',
    message: '',
    captchaSvg: '',
    captchaToken: '',
    onClose: () => setSlideInConfig((prev) => ({ ...prev, isOpen: false })),
    onConfirm: null,
    onCaptchaSubmit: null,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const emailParam = queryParams.get('email');

    if (token && emailParam) {
      setResetToken(token);
      setEmail(emailParam);
    }
  }, [location.search]);

  useEffect(() => {
    // Check if the reset button should be disabled
    const resetButtonReenableTime = localStorage.getItem('resetButtonReenableTime');
    if (resetButtonReenableTime) {
      const timeLeft = resetButtonReenableTime - Date.now();
      if (timeLeft > 0) {
        setResetButtonDisabled(true);
        setResetButtonText('Password reset link sent to your email.');
        // Set a timeout to re-enable the button after the remaining time
        if (resetButtonTimeoutRef.current) {
          clearTimeout(resetButtonTimeoutRef.current);
        }
        resetButtonTimeoutRef.current = setTimeout(() => {
          setResetButtonDisabled(false);
          setResetButtonText('Send Request');
          localStorage.removeItem('resetButtonReenableTime');
        }, timeLeft);

        // Clean up the timeout if component unmounts
        return () => {
          if (resetButtonTimeoutRef.current) {
            clearTimeout(resetButtonTimeoutRef.current);
          }
        };
      } else {
        // Time has passed, remove the item
        localStorage.removeItem('resetButtonReenableTime');
      }
    }
  }, []);

  // Function to fetch CAPTCHA from the server
  const fetchCaptcha = async () => {
    try {
      const response = await axios.get('https://z.ninovium.dev/captcha');
      const { captchaSvg, captchaToken } = response.data;
      // Log the fetched CAPTCHA data for debugging
      console.log('Fetched CAPTCHA Data:', { captchaSvg, captchaToken });
      return { captchaSvg, captchaToken };
    } catch (err) {
      console.error('Failed to load CAPTCHA:', err);
      showAlert('Error', 'Failed to load CAPTCHA. Please try again.');
      return null;
    }
  };

  // Function to display alerts
  const showAlert = (title, message) => {
    setSlideInConfig({
      isOpen: true,
      type: 'alert',
      title,
      message,
      onClose: () => setSlideInConfig((prev) => ({ ...prev, isOpen: false })),
      onConfirm: null,
      onCaptchaSubmit: null,
    });
  };

  // Function to display CAPTCHA via SlideIn
  const showCaptcha = async () => {
    const captchaData = await fetchCaptcha();
    if (captchaData) {
      setSlideInConfig({
        isOpen: true,
        type: 'captcha',
        captchaSvg: `data:image/svg+xml;base64,${btoa(captchaData.captchaSvg)}`,
        captchaToken: captchaData.captchaToken,
        onClose: () => setSlideInConfig((prev) => ({ ...prev, isOpen: false })),
        onCaptchaSubmit: handleCaptchaSubmit,
        title: '',
        message: '',
      });
    }
  };

  // Handle CAPTCHA submission
  const handleCaptchaSubmit = (userInput, token) => {
    console.log('User Input:', userInput, 'Token:', token); // Debugging
    setSlideInConfig((prev) => ({ ...prev, isOpen: false }));
    handleAction(userInput, token);
  };

  // Handle Signup or Login action
  const handleAction = async (captchaInput, token) => {
    setButtonDisabled(true);

    try {
      const hashResult = await Argon2.hash(password, email, {
        hashLength: 32,
        memory: 1024,
        parallelism: 1,
        mode: Argon2Mode.Argon2id,
        iterations: 1,
      });

      if (isLogin) {
        // Login flow
        const { data } = await axios.post('https://z.ninovium.dev/login', {
          email,
          passwordhash: hashResult.hex,
          captcha: captchaInput,
          captchaToken: token,
        });

        if (data.token) {
          localStorage.setItem('token', data.token);
          navigate('/');
        } else {
          showAlert('Error', 'Login failed, please try again.');
        }
      } else {
        // Signup flow
        const { data } = await axios.post('https://z.ninovium.dev/register', {
          username,
          email,
          passwordhash: hashResult.hex,
          captcha: captchaInput,
          captchaToken: token,
        });

        if (data.message.includes('User registered successfully')) {
          showAlert('Success', 'Please verify your email to continue.');
        } else {
          showAlert('Error', data.message);
        }
      }
    } catch (error) {
      console.error('Error during action:', error);
      showAlert(
        'Error',
        error.response?.data?.message ||
          `${isLogin ? 'Login' : 'Registration'} failed.`
      );
    } finally {
      setButtonDisabled(false);
    }
  };

  // Handle Password Reset
  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      showAlert('Error', 'Passwords do not match.');
      return;
    }

    setResetButtonDisabled(true);

    try {
      const hashResult = await Argon2.hash(password, email, {
        hashLength: 32,
        memory: 1024,
        parallelism: 1,
        mode: Argon2Mode.Argon2id,
        iterations: 1,
      });

      await axios.post('https://z.ninovium.dev/reset-password', {
        token: resetToken,
        newPasswordHash: hashResult.hex,
      });

      showAlert('Success', 'Password reset successfully. Please log in.');
      setResetToken('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error during password reset:', error);
      showAlert('Error', 'Failed to reset password.');
    } finally {
      setResetButtonDisabled(false);
    }
  };

  // Handle Reset Password Request
  const handlePasswordResetRequest = async () => {
    setResetButtonDisabled(true);
    setResetButtonText('Sending...');

    try {
      await axios.post('https://z.ninovium.dev/reset-password-request', { email });
      setResetButtonText('Password reset link sent to your email.');
      // Save the re-enable time in localStorage
      const reenableTime = Date.now() + 600000; // 10 minutes from now
      localStorage.setItem('resetButtonReenableTime', reenableTime);

      // Start a timeout to re-enable the button after 10 minutes
      if (resetButtonTimeoutRef.current) {
        clearTimeout(resetButtonTimeoutRef.current);
      }
      resetButtonTimeoutRef.current = setTimeout(() => {
        setResetButtonDisabled(false);
        setResetButtonText('Send Request');
        localStorage.removeItem('resetButtonReenableTime');
      }, 600000); // 600,000 milliseconds = 10 minutes
    } catch (error) {
      console.error('Error during password reset request:', error);
      setResetButtonText('Failed to send password reset link.');
      setResetButtonDisabled(false);
    }
  };

  return (
    <div style={styles.mainContainer}>
      {/* SlideIn Component */}
      <SlideIn
        isOpen={slideInConfig.isOpen}
        type={slideInConfig.type}
        title={slideInConfig.title}
        message={slideInConfig.message}
        onClose={slideInConfig.onClose}
        onConfirm={slideInConfig.onConfirm}
        onCaptchaSubmit={slideInConfig.onCaptchaSubmit}
        captchaSvg={slideInConfig.captchaSvg}
        captchaToken={slideInConfig.captchaToken}
      />
      <div style={styles.formContainer}>
        <div style={styles.sliderContainer}>
          <div style={styles.sliderButton} onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? 'Switch to Sign Up' : 'Switch to Sign In'}
          </div>
        </div>

        {resetToken ? (
          // Password Reset Form
          <>
            <input
              style={styles.input}
              type="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              style={styles.input}
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              style={styles.actionButton}
              onClick={handleResetPassword}
              disabled={resetButtonDisabled}
            >
              {resetButtonDisabled ? 'Changing...' : 'Change Password'}
            </button>
          </>
        ) : isResetPassword ? (
          // Reset Password Request Form
          <>
            <input
              style={styles.input}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              style={{
                ...styles.actionButton,
                backgroundColor: resetButtonDisabled ? '#ccc' : theme.actionButtonBackgroundColor,
                cursor: resetButtonDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={handlePasswordResetRequest}
              disabled={resetButtonDisabled}
            >
              {resetButtonDisabled ? 'Password reset link sent to your email.' : 'Send Request'}
            </button>
          </>
        ) : (
          // Signup or Login Form
          <>
            {!isLogin && (
              <input
                style={styles.input}
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            )}
            <input
              style={styles.input}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              style={styles.input}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              style={styles.actionButton}
              onClick={showCaptcha}
              disabled={buttonDisabled}
            >
              {buttonDisabled ? 'Processing...' : isLogin ? 'Sign In' : 'Sign Up'}
            </button>
            <div style={styles.linkText} onClick={() => setIsResetPassword(true)}>
              Forgot your password? Reset it.
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Signup;
