// index.js
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import App from './App';
import Payment from './payment';
import Meow from './meow';
import Signup from './signup/signup';
import Documentation from './docs';
import Tos from './tos';
import Portal from './portal/portal';
import GetStarted from './getstarted';
import DevelopmentTeam from './devteam';
import Support from './support';
import { FaBars, FaTimes } from 'react-icons/fa';
import { MdDarkMode, MdLightMode } from 'react-icons/md';

// ----------------------- Theme Constants ----------------------- //

const lightTheme = {
  background: '#f5f5f5',
  text: '#555',
  text2: '#f1f1f1',
  primary: '#4f47a7',
  secondary: '#705697',
  accent: '#4e3c69',
  headerBackground: '#f5f5f5',
  navBackground: '#f5f5f5',
  logo: '#4f47a7'
};

const darkTheme = {
  background: '#2e3440',
  text: '#d8dee9',
  text2: '#f1f1f1',
  primary: '#5a5475',
  secondary: '#9673d3',
  accent: '#5E81AC',
  headerBackground: '#2e3440',
  navBackground: '#2e3440',
  logo: '#9673d3'
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

// ----------------------- AuthContext ----------------------- //

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isTokenValid, setIsTokenValid] = useState(false);

  const validateToken = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch(
          `https://z.ninovium.dev/v1/auth/validate?token=${token}`
        );
        const data = await response.json();
        if (data.message === 'Token is valid.') {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      } catch (error) {
        console.error('Token validation failed:', error);
        setIsTokenValid(false);
      }
    } else {
      setIsTokenValid(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <AuthContext.Provider value={{ isTokenValid, validateToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// ----------------------- ThemeContext ----------------------- //

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const savedTheme = localStorage.getItem('theme') || 'light';
  const [currentTheme, setCurrentTheme] = useState(savedTheme);
  const [themeTransition, setThemeTransition] = useState(false);

  useEffect(() => {
    localStorage.setItem('theme', currentTheme);
  }, [currentTheme]);

  return (
    <ThemeContext.Provider
      value={{ currentTheme, setCurrentTheme, themeTransition, setThemeTransition }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

// ----------------------- Replicate Function ----------------------- //

const replicate = () => {
  const domain = '.ninovium.dev';
  Object.keys(localStorage).forEach((key) => {
    const value = localStorage.getItem(key);
    if (value) {
      document.cookie = `${key}=${encodeURIComponent(
        value
      )}; path=/; domain=${domain}; SameSite=None; Secure`;
    }
  });
};

// ----------------------- Custom Hooks ----------------------- //

// Hook to replicate localStorage to cookies on route change
const useReplicate = () => {
  const location = useLocation();

  useEffect(() => {
    replicate();
  }, [location]);
};

// Hook for fade-out and fade-in animations on route change
const useFadeTransition = () => {
  const [isFading, setIsFading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      setIsFading(true);
      const timer = setTimeout(() => {
        setIsFading(false);
      }, 200); // Duration of fade-in animation
      return () => clearTimeout(timer);
    }
  }, [location]);

  const handleNavigation = (path) => {
    setIsFading(true);
    setTimeout(() => {
      navigate(path);
    }, 200); // Duration of fade-out animation
  };

  return { isFading, handleNavigation };
};

// Hook to log navigation events
const useNavigationLogger = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(`Navigated to: ${location.pathname}`);
  }, [location]);
};

// ----------------------- Header Component ----------------------- //

const Header = ({ handleNavigation }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const { isTokenValid } = useContext(AuthContext);
  const { currentTheme, setCurrentTheme, setThemeTransition } = useContext(ThemeContext);
  const theme = themes[currentTheme];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
        setMenuOpen(false); // Close menu when resizing to larger view
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleTheme = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setCurrentTheme(newTheme);

    // Trigger fade out and refresh
    setThemeTransition(true);
    setTimeout(() => {
      window.location.reload();
    }, 200); // Duration of fade-out animation
  };

  const themeButtonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '24px',
    padding: '0.5rem',
    color: theme.text,
  };

  return (
    <header
      style={{
        width: '100%',
        padding: '1rem 0',
        display: 'flex',
        background: theme.headerBackground,
        justifyContent: 'center',
        position: 'relative',
        color: theme.text,
      }}
    >
      <div
        style={{
          width: '90%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '1400px',
        }}
      >
        {/* Logo Section */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '50px',
            cursor: 'pointer',
          }}
          onClick={() => handleNavigation('/')}
        >
          <img
            src={currentTheme === 'light' ? "https://z.ninovium.dev/Logo.svg" : "https://z.ninovium.dev/Logo2.svg"}
            alt="Ninovium Logo"
            style={{ height: '30px', marginRight: '10px' }}
          />
          <h1
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: theme.logo,
              lineHeight: '1',
            }}
          >
            Ninovium
          </h1>
        </div>

        {/* Mobile View: Hamburger Menu */}
        {isMobileView ? (
          <>
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '24px',
                color: theme.text,
              }}
              aria-label="Menu Toggle"
            >
              {menuOpen ? <FaTimes /> : <FaBars />}
            </button>

            {/* Mobile Menu */}
            <nav
              style={{
                display: menuOpen ? 'flex' : 'none',
                flexDirection: 'column',
                gap: '1rem',
                position: 'absolute',
                top: '70px',
                right: '10px',
                backgroundColor: theme.navBackground,
                padding: '1rem',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                zIndex: 1000,
              }}
            >
              {/* Navigation Links */}
              <a
                onClick={() => {
                  handleNavigation('/');
                  setMenuOpen(false);
                }}
                style={{
                  textDecoration: 'none',
                  color: theme.text,
                  fontSize: '1.1rem',
                  cursor: 'pointer',
                }}
              >
                Home
              </a>
              <a
                onClick={() => {
                  handleNavigation('/documentation');
                  setMenuOpen(false);
                }}
                style={{
                  textDecoration: 'none',
                  color: theme.text,
                  fontSize: '1.1rem',
                  cursor: 'pointer',
                }}
              >
                Documentation
              </a>
              <a
                onClick={() => {
                  handleNavigation('/support');
                  setMenuOpen(false);
                }}
                style={{
                  textDecoration: 'none',
                  color: theme.text,
                  fontSize: '1.1rem',
                  cursor: 'pointer',
                }}
              >
                Support
              </a>
              <a
                onClick={() => {
                  handleNavigation('/terms');
                  setMenuOpen(false);
                }}
                style={{
                  textDecoration: 'none',
                  color: theme.text,
                  fontSize: '1.1rem',
                  cursor: 'pointer',
                }}
              >
                Terms Of Service
              </a>

              {/* Theme Toggle Button */}
              <button
                onClick={toggleTheme}
                style={{
                  ...themeButtonStyles,
                  alignSelf: 'flex-start',
                }}
                aria-label="Toggle Theme"
              >
                {currentTheme === 'light' ? <MdDarkMode /> : <MdLightMode />}
              </button>

              {/* Authentication Buttons */}
              {isTokenValid ? (
                <button
                  onClick={() => {
                    handleNavigation('/portal');
                    setMenuOpen(false);
                  }}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: theme.secondary,
                    color: theme.text2,
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    fontFamily: '"Baloo 2", sans-serif',
                    lineHeight: '1',
                  }}
                >
                  Portal
                </button>
              ) : (
                <>
                  <button
                    onClick={() => {
                      handleNavigation('/register');
                      setMenuOpen(false);
                    }}
                    style={{
                      padding: '0.5rem 1rem',
                      backgroundColor: theme.secondary,
                      color: theme.text2,
                      border: 'none',
                      borderRadius: '4px',
                      fontSize: '16px',
                      cursor: 'pointer',
                      fontFamily: '"Baloo 2", sans-serif',
                      lineHeight: '1',
                    }}
                  >
                    Sign In
                  </button>
                  <button
                    onClick={() => {
                      handleNavigation('/register');
                      setMenuOpen(false);
                    }}
                    style={{
                      padding: '0.5rem 1rem',
                      backgroundColor: theme.secondary,
                      color: theme.text2,
                      border: 'none',
                      borderRadius: '4px',
                      fontSize: '16px',
                      cursor: 'pointer',
                      fontFamily: '"Baloo 2", sans-serif',
                      lineHeight: '1',
                    }}
                  >
                    Sign Up
                  </button>
                </>
              )}
            </nav>
          </>
        ) : (
          /* Desktop View: Standard Navigation Links */
          <nav style={{ display: 'flex', gap: '1rem' }}>
            <a
              onClick={() => handleNavigation('/')}
              style={{
                textDecoration: 'none',
                color: theme.text,
                fontSize: '1.1rem',
                cursor: 'pointer',
              }}
            >
              Home
            </a>
            <a
              onClick={() => handleNavigation('/documentation')}
              style={{
                textDecoration: 'none',
                color: theme.text,
                fontSize: '1.1rem',
                cursor: 'pointer',
              }}
            >
              Documentation
            </a>
            <a
              onClick={() => handleNavigation('/support')}
              style={{
                textDecoration: 'none',
                color: theme.text,
                fontSize: '1.1rem',
                cursor: 'pointer',
              }}
            >
              Support
            </a>
            <a
              onClick={() => handleNavigation('/terms')}
              style={{
                textDecoration: 'none',
                color: theme.text,
                fontSize: '1.1rem',
                cursor: 'pointer',
              }}
            >
              Terms Of Service
            </a>
          </nav>
        )}

        {/* Desktop View: Theme Toggle and Authentication Buttons */}
        {!isMobileView && (
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <button
              onClick={toggleTheme}
              style={themeButtonStyles}
              aria-label="Toggle Theme"
            >
              {currentTheme === 'light' ? <MdDarkMode /> : <MdLightMode />}
            </button>
            {isTokenValid ? (
              <button
                onClick={() => handleNavigation('/portal')}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: theme.secondary,
                  color: theme.text2,
                  border: 'none',
                  borderRadius: '4px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  fontFamily: '"Baloo 2", sans-serif',
                  lineHeight: '1',
                }}
              >
                Portal
              </button>
            ) : (
              <>
                <button
                  onClick={() => handleNavigation('/register')}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: theme.secondary,
                    color: theme.text2,
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    fontFamily: '"Baloo 2", sans-serif',
                    lineHeight: '1',
                  }}
                >
                  Sign In
                </button>
                <button
                  onClick={() => handleNavigation('/register')}
                  style={{
                    padding: '0.5rem 1rem',
                    backgroundColor: theme.secondary,
                    color: theme.text2,
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    fontFamily: '"Baloo 2", sans-serif',
                    lineHeight: '1',
                  }}
                >
                  Sign Up
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

// ----------------------- AppRoutes Component ----------------------- //

const AppRoutes = () => {
  // Use custom hooks
  useReplicate();
  useNavigationLogger();
  const { isFading, handleNavigation } = useFadeTransition();
  const { validateToken } = useContext(AuthContext);
  const { currentTheme } = useContext(ThemeContext);
  const theme = themes[currentTheme];

  const location = useLocation();

  useEffect(() => {
    validateToken();
  }, [location, validateToken]);

  const fadeInStyles = {
    animation: 'fadeInPop 0.3s ease-in forwards',
  };

  const fadeOutStyles = {
    animation: 'fadeOutShrink 0.3s ease-out forwards',
  };

  const keyframesFade = `
    @keyframes fadeInPop {
      0% { opacity: 0; transform: scale(0.95); }
      100% { opacity: 1; transform: scale(1.0); }
    }
    @keyframes fadeOutShrink {
      0% { opacity: 1; transform: scale(1.0); }
      100% { opacity: 0; transform: scale(0.95); }
    }
  `;

  return (
    <div
      style={{
        background: theme.background,
        minHeight: '100vh',
        overflow: 'hidden', // Prevents any overflow issues
        color: theme.text,
      }}
    >
      <style>{keyframesFade}</style>
      <Header handleNavigation={handleNavigation} />
      <div
        style={{
          maxHeight: 'calc(100vh - 70px)', // Adjusts for header height
          ...(isFading ? fadeOutStyles : fadeInStyles),
        }}
      >
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/paydemo" element={<Meow />} />
          <Route path="/purchase" element={<Payment />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/terms" element={<Tos />} />
          <Route path="/portal" element={<Portal />} />
          <Route path="/support" element={<Support />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/devteam" element={<DevelopmentTeam />} />
        </Routes>
      </div>
    </div>
  );
};

// ----------------------- ThemeWrapper Component ----------------------- //

const ThemeWrapper = ({ children }) => {
  const { themeTransition, currentTheme } = useContext(ThemeContext);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const theme = themes[currentTheme];

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  const fadeInStyles = {
    animation: 'fadeIn 0.3s ease-in forwards',
  };

  const fadeOutStyles = {
    animation: 'fadeOut 0.3s ease-out forwards',
  };

  const keyframesFade = `
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
    @keyframes fadeOut {
      0% { opacity: 1; }
      100% { opacity: 0; }
    }
  `;

  return (
    <div
      style={{
        ...(themeTransition
          ? fadeOutStyles
          : isFirstLoad
          ? fadeInStyles
          : {}),
      }}
    >
      <style>{keyframesFade}</style>
      {children}
    </div>
  );
};

// ----------------------- DevModeWrapper Component ----------------------- //

const DevModeWrapper = ({ children }) => {
  const isDevMode = localStorage.getItem('dev') === 'true';
  return isDevMode ? (
    <>{children}</>
  ) : (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h1>Work In Progress</h1>
      <p>The site is currently under development. Please check back later.</p>
    </div>
  );
};

// ----------------------- Root Rendering ----------------------- //

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider>
      <ThemeWrapper>
        <AuthProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </AuthProvider>
      </ThemeWrapper>
    </ThemeProvider>
);
