import React, { useState, useEffect, useRef } from 'react';
// Import the theme constants
const lightTheme = {
  background: '#f5f5f5',
  text: '#555',
  text2: '#f1f1f1',
  primary: '#4f47a7',
  secondary: '#705697',
  accent: '#4e3c69',
  headerBackground: '#f5f5f5',
  navBackground: '#f5f5f5',
  logo: '#4f47a7'
};

const darkTheme = {
  background: '#2e3440',
  text: '#d8dee9',
  text2: '#f1f1f1',
  primary: '#5a5475',
  secondary: '#9673d3',
  accent: '#5E81AC',
  headerBackground: '#2e3440',
  navBackground: '#2e3440',
  logo: '#9673d3'
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export default function Meow() {
  const [fadeClass, setFadeClass] = useState('fade-in');
  const [isSlideInOpen, setIsSlideInOpen] = useState(false);

  // Read the current theme from localStorage
  const currentTheme = localStorage.getItem('theme') || 'light';
  const theme = themes[currentTheme];

  useEffect(() => {
    // On mount, ensure the page fades in
    setFadeClass('fade-in');

    // Show the SlideIn notification
    setIsSlideInOpen(true);
  }, []);

  const handleSlideInClose = () => {
    setIsSlideInOpen(false);
  };

  const handleGetStartedClick = () => {
    // On link click, fade out before navigating
    setFadeClass('fade-out');
    setTimeout(() => {
      // Check if there's a token in localStorage under 'token'
      const token = localStorage.getItem('token');
      if (token) {
        // Navigate to '/portal'
        window.location.href = '/portal';
      } else {
        // Navigate to '/register'
        window.location.href = '/register';
      }
    }, 500); // Match the duration of the fade-out animation
  };

  const handleJoinCommunityClick = () => {
    // Fade out before navigating
    setFadeClass('fade-out');
    setTimeout(() => {
      // Navigate to Discord community link
      window.location.href = 'https://discord.gg/ninovium';
    }, 500); // Match the duration of the fade-out animation
  };

  const notificationMessage =
    'Native support for Oracle Lua Decompiler. Allowing users to convert bytecode to readable Lua code.';

  return (
    <div
      className={fadeClass}
      style={{
        fontFamily: '"Baloo 2", sans-serif',
        backgroundColor: theme.background,
        color: theme.text,
        height: 'calc(100vh - 85px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: '1.2',
        transition: 'opacity 0.5s ease-in-out',
        opacity: fadeClass === 'fade-in' ? 1 : 0,
      }}
    >
      <HeroSection
        handleGetStartedClick={handleGetStartedClick}
        handleJoinCommunityClick={handleJoinCommunityClick}
        theme={theme} // Pass theme to HeroSection
      />
      <ImageSection theme={theme} currentTheme={currentTheme} /> {/* Pass currentTheme */}
      {/* Uncomment or adjust the import for SlideIn component if needed */}
      {/* <SlideIn
        isOpen={isSlideInOpen}
        message={notificationMessage}
        onClose={handleSlideInClose}
        theme={theme} // Pass theme to SlideIn if needed
      /> */}
    </div>
  );
}

const HeroSection = ({
  handleGetStartedClick,
  handleJoinCommunityClick,
  theme,
}) => (
  <section
    style={{
      textAlign: 'center',
      padding: '4rem 2rem',
    }}
  >
    <h2
      style={{
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        lineHeight: '1.1',
        color: theme.text,
      }}
    >
      Breaking boundaries that were never set.
    </h2>
    <p
      style={{
        fontSize: '1.2rem',
        marginBottom: '2rem',
        color: theme.text,
        lineHeight: '1.4',
      }}
    >
      Ninovium's powerful Lua debugging utility allows you to write and execute
      code like never before.
    </p>
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center',
        marginBottom: '-40px',
        flexWrap: 'wrap',
      }}
    >
      <button
        onClick={handleGetStartedClick}
        style={{
          padding: '0.75rem 1.5rem',
          backgroundColor: theme.secondary,
          color: theme.text2,
          border: 'none',
          borderRadius: '4px',
          fontSize: '18px',
          cursor: 'pointer',
          fontFamily: '"Baloo 2", sans-serif',
          lineHeight: '1',
        }}
      >
        Get Started
      </button>
      <button
        onClick={handleJoinCommunityClick}
        style={{
          padding: '0.75rem 1.5rem',
          backgroundColor: theme.secondary,
          color: theme.text2,
          border: 'none',
          borderRadius: '4px',
          fontSize: '18px',
          cursor: 'pointer',
          fontFamily: '"Baloo 2", sans-serif',
          lineHeight: '1',
        }}
      >
        Join The Community
      </button>
    </div>
  </section>
);

const ImageSection = ({ theme, currentTheme }) => {
  const imageRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const rect = imageRef.current.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Set image source based on the theme
  const imageSrc = currentTheme === 'dark'
    ? 'https://z.ninovium.dev/exc2.png'  // Image for dark mode
    : 'https://z.ninovium.dev/exc.png';   // Image for light mode

  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '2rem 0',
        boxSizing: 'border-box',
      }}
    >
      <img
        ref={imageRef}
        src={imageSrc} // Dynamically set src based on theme
        alt="Ninovium IDE Example"
        style={{
          maxWidth: '80%',
          height: 'auto',
          border: 'none',
          borderRadius: '9px',
          transform: isVisible ? 'translateY(0)' : 'translateY(100px)',
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
        }}
      />
    </section>
  );
};
