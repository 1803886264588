// Support.js
import React, { useEffect, useState } from 'react';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-lua';
import { MdPayment, MdOutlineSupportAgent } from 'react-icons/md';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaRegQuestionCircle } from 'react-icons/fa';
import axios from 'axios';
import SlideIn from './portal/slidin'; // Ensure this path is correct

// Styles Object (using styles from Portal.js)
const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#f5f5f5',
    minHeight: '100vh',
  },
  supportWidgetsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '20px',
  },
  supportWidget: (isSelected, isOtherSelected) => ({
    padding: '20px',
    backgroundColor: isSelected ? '#f7f7f7' : '#fff',
    borderRadius: '10px',
    cursor: 'pointer',
    border: isSelected ? '2px solid #7e56ef' : '2px solid transparent',
    opacity: isOtherSelected && !isSelected ? 0.5 : 1,
    transition: 'all 0.3s ease',
    textAlign: 'center',
  }),
  widgetIcon: {
    fontSize: '40px',
  },
  widgetTitle: {
    marginTop: '10px',
    color: '#4f47a7',
  },
  widgetDescription: {
    color: '#555',
  },
  articlesSection: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  article: {
    padding: '20px',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    position: 'relative',
  },
  articleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
    paddingBottom: '10px',
    marginBottom: '10px',
  },
  closeArticleButton: {
    background: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#999',
  },
};

// Support Component
function Support() {
  const [parsedContent, setParsedContent] = useState([]);
  const [loading, setLoading] = useState(false); // Initialize as true since we're loading

  // SlideIn state variables
  const [slideInConfig, setSlideInConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    showConfirm: false,
    onConfirm: null,
  });

  // State for managing selected widget
  const [selectedWidget, setSelectedWidget] = useState(null);

  // Content for support widgets
  const supportItems = [
    {
      id: 1,
      icon: <FaRegQuestionCircle size={40} />,
      name: 'General Inquiry',
      description: 'Get answers to general questions.',
    },
    {
      id: 2,
      icon: <MdOutlineSupportAgent size={40} />,
      name: 'Technical Support',
      description: 'Help with technical issues and bugs.',
    },
    {
      id: 3,
      icon: <MdPayment size={40} />,
      name: 'Billing & Account',
      description: 'Questions about billing or account settings.',
    },
    {
      id: 4,
      icon: <AiOutlineGlobal size={40} />,
      name: 'Website & Software',
      description: 'Report website or software issues or errors.',
    },
  ];

  // Handle widget click
  const handleWidgetClick = (id) => {
    if (selectedWidget === id) {
      // If the same widget is clicked again, deselect it
      setSelectedWidget(null);
    } else {
      setSelectedWidget(id);
    }
  };

  // Simulate content loading
  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 second delay
    return () => clearTimeout(timer);
  }, []);

  // SlideIn handling functions
  const showAlert = (title, message) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: false,
      onConfirm: null,
    });
  };

  const showConfirm = (title, message, action) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: true,
      onConfirm: action,
    });
  };

  if (loading) {
    return (
      <div style={{ ...styles.mainContainer }}>
        <div>Loading...</div>
        {/* Optionally, add a spinner or animated loader here */}
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <div style={styles.mainContainer}>
        {/* Support Widgets Grid */}
        <div style={styles.supportWidgetsGrid}>
          {supportItems.map((item) => (
            <div
              key={item.id}
              onClick={() => handleWidgetClick(item.id)}
              style={styles.supportWidget(
                selectedWidget === item.id,
                selectedWidget !== null && selectedWidget !== item.id
              )}
            >
              <div style={styles.widgetIcon}>{item.icon}</div>
              <h3 style={styles.widgetTitle}>{item.name}</h3>
              <p style={styles.widgetDescription}>{item.description}</p>
            </div>
          ))}
        </div>

        {/* Articles Section */}
        <div style={styles.articlesSection}>
          {selectedWidget === 1 && (
            <ArticleGeneralInquiry onClose={() => setSelectedWidget(null)} />
          )}
          {selectedWidget === 2 && (
            <ArticleTechnicalSupport onClose={() => setSelectedWidget(null)} />
          )}
          {selectedWidget === 3 && (
            <ArticleBillingAccount onClose={() => setSelectedWidget(null)} />
          )}
          {selectedWidget === 4 && (
            <ArticleWebsiteSoftware onClose={() => setSelectedWidget(null)} />
          )}
        </div>
      </div>

      {/* SlideIn for Alerts */}
      <SlideIn
        isOpen={slideInConfig.isOpen}
        title={slideInConfig.title}
        message={slideInConfig.message}
        onClose={() =>
          setSlideInConfig((prev) => ({ ...prev, isOpen: false }))
        }
        onConfirm={slideInConfig.onConfirm}
        showConfirm={slideInConfig.showConfirm}
      />
    </div>
  );
}

export default Support;

// Article Components
const ArticleGeneralInquiry = ({ onClose }) => {
  return (
    <div style={styles.article}>
      <div style={styles.articleHeader}>
        <h2>General Inquiry</h2>
        <button onClick={onClose} style={styles.closeArticleButton}>
          ×
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
        {/* Oracle Section */}
        <div
          style={{
            flex: 1,
            padding: '20px',
            background: 'linear-gradient(to bottom, #bed5e6, #87a4c5)',
            borderRadius: '10px',
            textAlign: 'left',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            src="https://z.ninovium.dev/oracle.png"
            alt="Oracle Logo"
            style={{
              height: '30rem',
              marginBottom: '10px',
              position: 'absolute',
              top: '10px',
              right: '10px',
              transform: 'rotate(15deg)',
              opacity: '0.2',
              zIndex: '0',
            }}
          />
          <iframe
            src="https://discord.com/widget?id=1258765614101696533&theme=light"
            width="100%"
            height="500"
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            style={{ zIndex: '1' }}
          />
        </div>

        {/* Ninovium Section */}
        <div
          style={{
            flex: 1,
            padding: '20px',
            background: 'linear-gradient(to bottom, #e1def2, #d4cdf1)',
            borderRadius: '10px',
            textAlign: 'left',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <img
            src="https://z.ninovium.dev/Logo.svg"
            alt="Ninovium Logo"
            style={{
              height: '25rem',
              marginBottom: '10px',
              position: 'absolute',
              top: '10px',
              right: '10px',
              transform: 'rotate(15deg)',
              opacity: '0.2',
              zIndex: '0',
            }}
          />
          <iframe
            src="https://discord.com/widget?id=1269807225392857189&theme=light"
            width="100%"
            height="500"
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts"
            style={{ zIndex: '1' }}
          />
        </div>
      </div>
    </div>
  );
};

const ArticleTechnicalSupport = ({ onClose }) => {
  return (
    <div style={styles.article}>
      <div style={styles.articleHeader}>
        <h2>Technical Support</h2>
        <button onClick={onClose} style={styles.closeArticleButton}>
          ×
        </button>
      </div>
      <div>
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

const ArticleBillingAccount = ({ onClose }) => {
  return (
    <div style={styles.article}>
      <div style={styles.articleHeader}>
        <h2>Billing & Account</h2>
        <button onClick={onClose} style={styles.closeArticleButton}>
          ×
        </button>
      </div>
      <div>
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

const ArticleWebsiteSoftware = ({ onClose }) => {
  return (
    <div style={styles.article}>
      <div style={styles.articleHeader}>
        <h2>Website & Software</h2>
        <button onClick={onClose} style={styles.closeArticleButton}>
          ×
        </button>
      </div>
      <div>
        <p>Coming Soon</p>
      </div>
    </div>
  );
}
