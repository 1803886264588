// Portal.js
import React, { useEffect, useState } from 'react';
import {
  FaCcStripe,
  FaCopy,
  FaDownload,
  FaMinus,
  FaPlus,
} from 'react-icons/fa';
import { FiRefreshCcw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SlideIn from './slidin'; // Ensure this path is correct

// Theme Constants
const lightTheme = {
  background: '#f5f5f5',
  text: '#555',
  text2: '#f1f1f1',
  primary: '#4f47a7',
  secondary: '#705697',
  accent: '#4e3c69',
  headerBackground: '#f5f5f5',
  portalbg1: '#f5f5f5',
  navBackground: '#f5f5f5',
  logo: '#4f47a7',
  inputBorder: '#ccc',
  inputFocusBorder: '#68217a',
  boxShadow: 'rgba(0, 0, 0, 0.1)',
};

const darkTheme = {
  background: '#2e3440',
  text: '#d8dee9',
  text2: '#f1f1f1',
  primary: '#5a5475',
  secondary: '#9673d3',
  accent: '#5E81AC',
  headerBackground: '#5a5475',
  portalbg1: '#3b4252',
  navBackground: '#434C5E',
  logo: '#9673d3',
  inputBorder: '#5a5475',
  inputFocusBorder: '#9673d3',
  boxShadow: 'rgba(0, 0, 0, 0.2)',
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

// Portal Component
const Portal = () => {
  const navigate = useNavigate();
  const [portalInfo, setPortalInfo] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [amount, setAmount] = useState(0);
  const [adjustedAmount, setAdjustedAmount] = useState(0);
  const [dueThisMonth, setDueThisMonth] = useState(0);
  const [copyKeyLabels, setCopyKeyLabels] = useState({});
  const [slideInConfig, setSlideInConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    showConfirm: false,
    onConfirm: null,
  });
  const token = localStorage.getItem('token'); // Get the token from localStorage

  // Read the current theme from localStorage
  const currentTheme = localStorage.getItem('theme') || 'light';
  const theme = themes[currentTheme];

  // Function to calculate the start date based on the end date
  const calculateStartDate = (endDate) => {
    const date = new Date(endDate);
    date.setMonth(date.getMonth() - 1);
    return date;
  };

  // Function to format dates
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  // Calculate adjusted amount after fees
  useEffect(() => {
    if (amount && amount > 0) {
      const adjusted =
        Math.round(((parseFloat(amount) + 0.3) / 0.971) * 100) / 100;
      setAdjustedAmount(adjusted);
    } else {
      setAdjustedAmount(0);
    }
  }, [amount]);

  // Calculate the total due this month based on subscriptions
  const calculateDueThisMonth = (subscriptions) => {
    let total = 0;
    subscriptions.forEach((sub) => {
      if (sub.subscribed) {
        total += parseFloat(sub.monthlyCost.replace('$', ''));
      }
    });
    return total;
  };

  // Fetch portal and subscription info
  useEffect(() => {
    if (token) {
      // Validate token
      axios
        .get(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => {
          const data = response.data;
          if (data.message === 'Token is valid.') {
            // Fetch portal info
            return axios.get(
              `https://z.ninovium.dev/v1/auth/portal-info?token=${token}`
            );
          } else {
            handleNavigation('/register');
            throw new Error('Invalid token');
          }
        })
        .then((response) => {
          const data = response.data;
          if (data && data.message === 'Token is valid.') {
            setPortalInfo({
              amount: data.amount,
              discord_userid: data.discord_userid,
            });
          } else {
            handleNavigation('/register');
          }
        })
        .catch(() => {});

      // Fetch subscriptions
      axios
        .get(`https://z.ninovium.dev/v1/subscriptions?token=${token}`)
        .then((response) => {
          const data = response.data;
          if (data.message === 'No subscriptions found.') {
            setSubscriptions([]);
          } else if (data && data.subscriptions) {
            const userSubscriptions = [
              {
                id: 'ninovium_ld',
                name: 'Ninovium Lua Debugger',
                status: data.subscriptions?.ninovium_ld?.subscribed
                  ? 'Active'
                  : 'Inactive',
                startDate: data.subscriptions?.ninovium_ld?.renewal_date
                  ? formatDate(
                      calculateStartDate(
                        data.subscriptions.ninovium_ld.renewal_date
                      )
                    )
                  : 'Not Subscribed',
                endDate: data.subscriptions?.ninovium_ld?.renewal_date
                  ? formatDate(data.subscriptions.ninovium_ld.renewal_date)
                  : 'Not Subscribed',
                monthlyCost: '$8.50',
                subscribed: data.subscriptions?.ninovium_ld?.subscribed || false,
              },
              {
                id: 'oracle',
                name: 'Oracle Lua Decompiler',
                status: data.subscriptions?.oracle?.subscribed
                  ? 'Active'
                  : 'Inactive',
                startDate: data.subscriptions?.oracle?.renewal_date
                  ? formatDate(
                      calculateStartDate(data.subscriptions.oracle.renewal_date)
                    )
                  : 'Not Subscribed',
                endDate: data.subscriptions?.oracle?.renewal_date
                  ? formatDate(data.subscriptions.oracle.renewal_date)
                  : 'Not Subscribed',
                monthlyCost: '$5.00',
                subscribed: data.subscriptions?.oracle?.subscribed || false,
              },
            ];

            setSubscriptions(userSubscriptions);
            setDueThisMonth(calculateDueThisMonth(userSubscriptions));
          }
        })
        .catch((error) =>
          console.error('Error fetching subscriptions:', error)
        );
    } else {
      handleNavigation('/register');
    }
  }, [navigate, token]);

  useEffect(() => {
    setDueThisMonth(calculateDueThisMonth(subscriptions));
  }, [subscriptions]);

  // Function to show alerts using SlideIn
  const showAlert = (title, message) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: false,
      onConfirm: null,
    });
  };

  // Function to show confirmations using SlideIn
  const showConfirm = (title, message, action) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: true,
      onConfirm: action,
    });
  };

  // Handle Stripe payment
  const handleStripePayment = () => {
    if (!amount || amount <= 0) {
      showAlert('Error', 'Please enter a valid amount to deposit.');
      return;
    }

    axios
      .post(
        `https://z.ninovium.dev/create-checkout-session?token=${token}&amount=${amount}`
      )
      .then((response) => {
        const data = response.data;
        if (data.url) {
          window.location.href = data.url;
        } else {
          showAlert('Error', 'Error creating checkout session. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        showAlert(
          'Error',
          'There was an issue processing your payment. Please try again.'
        );
      });
  };

  // Handle subscribe or unsubscribe
  const handleSubscribeOrUnsubscribe = (service, subscribed) => {
    const endpoint = subscribed ? 'unsubscribe' : 'purchase';

    if (subscribed) {
      showConfirm(
        'Confirm Unsubscription',
        `Are you sure you want to unsubscribe from ${
          service === 'oracle' ? 'Oracle Lua Decompiler' : 'Ninovium Lua Debugger'
        }?`,
        () => proceedUnsubscribe(service)
      );
      return;
    }

    axios
      .post(`https://z.ninovium.dev/v1/${endpoint}`, {
        token,
        service,
      })
      .then((response) => {
        const data = response.data;
        if (data.message) {
          showAlert('Success', data.message);
          if (!subscribed && service === 'ninovium_ld') {
            handleCopyKey(service);
          }
          window.location.reload();
        } else {
          showAlert(
            'Error',
            `Error processing ${
              subscribed ? 'unsubscription' : 'subscription'
            }.`
          );
        }
      })
      .catch((error) => {
        console.error(
          `Error processing ${subscribed ? 'unsubscription' : 'subscription'}:`,
          error
        );
        showAlert(
          'Error',
          `There was an issue with your ${
            subscribed ? 'unsubscription' : 'subscription'
          }. Please try again.`
        );
      });
  };

  // Proceed with unsubscription
  const proceedUnsubscribe = (service) => {
    axios
      .post(`https://z.ninovium.dev/v1/unsubscribe`, {
        token,
        service,
      })
      .then((response) => {
        const data = response.data;
        if (data.message) {
          showAlert('Success', data.message);
          window.location.reload();
        } else {
          showAlert('Error', data.error || 'Error processing unsubscription.');
        }
      })
      .catch((error) => {
        console.error('Error processing unsubscription:', error);
        showAlert(
          'Error',
          'There was an issue with your unsubscription. Please try again.'
        );
      });
  };

  // Handle Copy Key
  const handleCopyKey = (service) => {
    const productMap = {
      ninovium_ld: 'ninovium_ld',
      // Add other services here if they have keys
    };

    const product = productMap[service];
    if (!product) {
      showAlert('Error', 'This service does not have a key to copy.');
      return;
    }

    axios
      .post(`https://z.ninovium.dev/v1/get-key`, {
        token,
        product,
      })
      .then((response) => {
        const data = response.data;
        if (data && data.key) {
          copyToClipboard(data.key, service);
        } else {
          axios
            .post(`https://z.ninovium.dev/v1/generate-key`, {
              token,
              product,
            })
            .then((res) => {
              const newData = res.data;
              if (newData && newData.key) {
                copyToClipboard(newData.key, service);
              } else {
                showAlert('Error', 'Error generating key. Please try again.');
              }
            })
            .catch((error) => {
              console.error('Error generating key:', error);
              showAlert('Error', 'Error generating key. Please try again.');
            });
        }
      })
      .catch((error) => {
        console.error('Error retrieving key:', error);
        showAlert('Error', 'Error retrieving key. Please try again.');
      });
  };

  // Copy key to clipboard
  const copyToClipboard = (key, service) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(key)
        .then(() => {
          setCopyKeyLabels((prev) => ({
            ...prev,
            [service]: 'Copied!',
          }));
          setTimeout(() => {
            setCopyKeyLabels((prev) => ({
              ...prev,
              [service]: 'Copy Key',
            }));
          }, 2000);
          showAlert('Success', 'Key copied to clipboard!');
        })
        .catch((error) => {
          console.error('Failed to copy key:', error);
          showAlert('Error', 'Failed to copy the key. Please try again.');
        });
    } else {
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = key;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      try {
        document.execCommand('copy');
        setCopyKeyLabels((prev) => ({
          ...prev,
          [service]: 'Copied!',
        }));
        setTimeout(() => {
          setCopyKeyLabels((prev) => ({
            ...prev,
            [service]: 'Copy Key',
          }));
        }, 2000);
        showAlert('Success', 'Key copied to clipboard!');
      } catch (error) {
        console.error('Fallback: Failed to copy key:', error);
        showAlert(
          'Error',
          'Fallback: Failed to copy the key. Please try again.'
        );
      } finally {
        document.body.removeChild(tempTextArea);
      }
    }
  };

  // Handle Reset Key
  const handleResetKey = (service) => {
    const productMap = {
      ninovium_ld: 'ninovium_ld',
      // Add other services here if they have keys
    };

    const product = productMap[service];
    if (!product) {
      showAlert('Error', 'This service does not have a key to reset.');
      return;
    }

    // Show confirmation
    showConfirm(
      'Confirm Reset Key',
      'Are you sure you want to reset your key? This action cannot be undone.',
      () => proceedResetKey(service)
    );
  };

  const proceedResetKey = (service) => {
    const productMap = {
      ninovium_ld: 'ninovium_ld',
      // Add other services here if they have keys
    };

    const product = productMap[service];

    axios
      .post(`https://z.ninovium.dev/v1/get-key`, {
        token,
        product,
      })
      .then((response) => {
        const data = response.data;
        if (data && data.key) {
          const currentKey = data.key;

          // Now call reset-key endpoint
          axios
            .post(`https://z.ninovium.dev/v1/reset-key`, {
              token,
              key: currentKey,
            })
            .then((res) => {
              const resetData = res.data;
              if (resetData && resetData.message) {
                showAlert('Success', 'Key reset successfully.');
              } else {
                showAlert('Error', 'Error resetting key. Please try again.');
              }
            })
            .catch((error) => {
              console.error('Error resetting key:', error);
              showAlert('Error', 'Error resetting key. Please try again.');
            });
        } else {
          showAlert('Error', 'Error retrieving key. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error retrieving key:', error);
        showAlert('Error', 'Error retrieving key. Please try again.');
      });
  };

  // Handle navigation using useNavigate
  const handleNavigation = (path) => {
    navigate(path);
  };

  // Check if a service is subscribed
  const isSubscribed = (service) => {
    const subscription = subscriptions.find((sub) => sub.id === service);
    return subscription && subscription.subscribed;
  };

  // Get button text based on subscription status
  const getButtonText = (service) => {
    const subscription = subscriptions.find((sub) => sub.id === service);
    return subscription && subscription.subscribed
      ? 'Unsubscribe'
      : 'Subscribe';
  };

  // Function to check if Discord is linked
  const isDiscordLinked = () => {
    return (
      portalInfo &&
      portalInfo.discord_userid &&
      portalInfo.discord_userid !== 'null'
    );
  };

  return (
    <div
      style={{
        backgroundColor: theme.background,
        overflow: 'hidden', // Prevent outer scrolling
        height: 'calc(100vh - 85px)', // Use fixed height instead of minHeight
        color: theme.text,
        fontFamily: '"Baloo 2", sans-serif',
        display: 'flex', // Enable Flexbox
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: '2rem',
          maxWidth: '1400px',
          margin: '0 auto',
          flex: 1, // Allow this container to grow and fill available space
          overflowY: 'auto', // Enable vertical scrolling for inner content
        }}
      >
        <div
          style={{
            padding: '2rem',
            backgroundColor: theme.portalbg1,
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            boxShadow: `0 2px 8px ${theme.boxShadow}`,
            flex: '1', // Ensure this section fills the available space
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                backgroundColor: 'transparent',
                borderRight: `1px solid ${theme.inputBorder}`,
                borderBottom: `1px solid ${theme.inputBorder}`,
                padding: '1rem',
              }}
            >
              <h2 style={{ fontWeight: '500', marginBottom: '-20px' }}>
                Account Information
              </h2>
              {portalInfo ? (
                <div>
                  <p>
                    <span style={{ fontWeight: '500', marginBottom: '-30px' }}>
                      Balance (USD)
                    </span>
                    <br />$
                    {portalInfo.amount
                      ? Number(portalInfo.amount).toLocaleString()
                      : 0}
                  </p>
                  <p>
                    <span style={{ fontWeight: '500', marginBottom: '-30px' }}>
                      Paid this month
                    </span>
                    <br />${dueThisMonth.toFixed(2)}
                  </p>
                </div>
              ) : (
                <p>Loading account information...</p>
              )}
            </div>

            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                backgroundColor: 'transparent',
                borderBottom: `1px solid ${theme.inputBorder}`,
                padding: '1rem',
              }}
            >
              <h2 style={{ fontWeight: '500', marginBottom: '-10px' }}>
                Deposit
              </h2>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <input
                    type="number"
                    placeholder="Enter amount"
                    value={amount}
                    onFocus={(e) =>
                      (e.target.style.border = `1px solid ${theme.inputFocusBorder}`)
                    }
                    onBlur={(e) =>
                      (e.target.style.border = `1px solid ${theme.inputBorder}`)
                    }
                    onChange={(e) => setAmount(e.target.value)}
                    style={{
                      width: 'calc(100% - 25px)',
                      padding: '14.5px',
                      maxHeight: '8.5px',
                      borderRadius: '4px 0 0 4px',
                      border: `1px solid ${theme.inputBorder}`,
                      fontSize: '16px',
                      outline: 'none',
                      flex: '1',
                      color: theme.text,
                      backgroundColor: theme.background,
                    }}
                  />
                  <button
                    onClick={handleStripePayment}
                    disabled={adjustedAmount < 10}
                    style={{
                      padding: '0.5rem 1rem',
                      backgroundColor: theme.secondary,
                      color: theme.text2,
                      cursor: adjustedAmount >= 10 ? 'pointer' : 'not-allowed',
                      display: 'flex',
                      border: `1px solid ${theme.inputBorder}`,
                      borderRight: 'none',
                      alignItems: 'center',
                      borderRadius: '0px 4px 4px 0',
                      gap: '0.5rem',
                      opacity: adjustedAmount >= 10 ? 1 : 0.6,
                      fontFamily: '"Baloo 2", sans-serif',
                    }}
                  >
                    <FaCcStripe /> Stripe
                  </button>
                </div>
                <p>
                  After Stripe fees: <strong>${adjustedAmount}</strong>
                </p>
              </div>
            </div>
          </div>

          {/* Subscriptions Section */}
          <div>
            <h2 style={{ fontWeight: '500' }}>Subscriptions</h2>
            {subscriptions.length > 0 ? (
              <div style={{ width: '100%', overflowX: 'auto' }}>
                <table
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    color: theme.text,
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderBottom: `2px solid ${theme.inputBorder}`,
                          padding: '0.75rem',
                          textAlign: 'left',
                          fontWeight: '500',
                          backgroundColor: theme.navBackground,
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          borderBottom: `2px solid ${theme.inputBorder}`,
                          padding: '0.75rem',
                          textAlign: 'left',
                          fontWeight: '500',
                          backgroundColor: theme.navBackground,
                        }}
                      >
                        Start Date
                      </th>
                      <th
                        style={{
                          borderBottom: `2px solid ${theme.inputBorder}`,
                          padding: '0.75rem',
                          textAlign: 'left',
                          fontWeight: '500',
                          backgroundColor: theme.navBackground,
                        }}
                      >
                        Renewal Date
                      </th>
                      <th
                        style={{
                          borderBottom: `2px solid ${theme.inputBorder}`,
                          padding: '0.75rem',
                          textAlign: 'left',
                          fontWeight: '500',
                          backgroundColor: theme.navBackground,
                        }}
                      >
                        Monthly Cost
                      </th>
                      <th
                        style={{
                          borderBottom: `2px solid ${theme.inputBorder}`,
                          padding: '0.75rem',
                          textAlign: 'left',
                          fontWeight: '500',
                          backgroundColor: theme.navBackground,
                        }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptions.map((sub) => (
                      <tr key={sub.id}>
                        <td
                          style={{
                            borderBottom: `1px solid ${theme.inputBorder}`,
                            padding: '0.75rem',
                            verticalAlign: 'top',
                          }}
                        >
                          {sub.name}
                        </td>
                        <td
                          style={{
                            borderBottom: `1px solid ${theme.inputBorder}`,
                            padding: '0.75rem',
                            verticalAlign: 'top',
                          }}
                        >
                          {sub.startDate}
                        </td>
                        <td
                          style={{
                            borderBottom: `1px solid ${theme.inputBorder}`,
                            padding: '0.75rem',
                            verticalAlign: 'top',
                          }}
                        >
                          {sub.endDate}
                        </td>
                        <td
                          style={{
                            borderBottom: `1px solid ${theme.inputBorder}`,
                            padding: '0.75rem',
                            verticalAlign: 'top',
                          }}
                        >
                          {sub.monthlyCost}
                        </td>
                        <td
                          style={{
                            borderBottom: `1px solid ${theme.inputBorder}`,
                            padding: '0.75rem',
                            verticalAlign: 'top',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              gap: '0.5rem',
                              flexWrap: 'wrap',
                            }}
                          >
                            {/* Unsubscribe/Subscribe Button First */}
                            <button
                              onClick={() =>
                                handleSubscribeOrUnsubscribe(sub.id, sub.subscribed)
                              }
                              style={{
                                padding: '0.5rem 1rem',
                                border: 'none',
                                borderRadius: '4px',
                                fontSize: '16px',
                                fontFamily: '"Baloo 2", sans-serif',
                                lineHeight: '1',
                                cursor: 'pointer',
                                backgroundColor: sub.subscribed
                                  ? '#c0392b'
                                  : '#27ae60',
                                color: theme.text2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                              }}
                            >
                              {sub.subscribed ? <FaMinus /> : <FaPlus />}{' '}
                              {getButtonText(sub.id)}
                            </button>
                            {/* Additional Actions */}
                            {sub.subscribed && sub.id === 'ninovium_ld' && (
                              <>
                                <button
                                  onClick={() => handleCopyKey(sub.id)}
                                  style={{
                                    padding: '0.5rem 1rem',
                                    border: 'none',
                                    borderRadius: '4px',
                                    fontSize: '16px',
                                    fontFamily: '"Baloo 2", sans-serif',
                                    lineHeight: '1',
                                    cursor: 'pointer',
                                    backgroundColor: theme.secondary,
                                    color: theme.text2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <FaCopy /> {copyKeyLabels[sub.id] || 'Copy Key'}
                                </button>
                                <button
                                  onClick={() => handleResetKey(sub.id)}
                                  style={{
                                    padding: '0.5rem 1rem',
                                    border: 'none',
                                    borderRadius: '4px',
                                    fontSize: '16px',
                                    fontFamily: '"Baloo 2", sans-serif',
                                    lineHeight: '1',
                                    cursor: 'pointer',
                                    backgroundColor: '#f39c12',
                                    color: theme.text2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <FiRefreshCcw /> Reset Key
                                </button>
                                <a
                                  href="https://z.ninovium.dev/release/ninovium-installer.exe"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    padding: '0.5rem 1rem',
                                    border: 'none',
                                    borderRadius: '4px',
                                    fontSize: '16px',
                                    fontFamily: '"Baloo 2", sans-serif',
                                    lineHeight: '1',
                                    cursor: 'pointer',
                                    backgroundColor: theme.secondary,
                                    color: theme.text2,
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <FaDownload /> Download
                                </a>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No active subscriptions.</p>
            )}
          </div>
          <div>
            {isDiscordLinked() ? (
              <p>Your account is linked to Discord.</p>
            ) : (
              <>
                <p>
                  Link your account to Discord to receive updates and support.
                </p>
                <a
                  href={`https://z.ninovium.dev/link/discord?token=${token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    style={{
                      padding: '0.5rem 1rem',
                      border: 'none',
                      borderRadius: '4px',
                      fontSize: '16px',
                      fontFamily: '"Baloo 2", sans-serif',
                      lineHeight: '1',
                      cursor: 'pointer',
                      backgroundColor: '#7289da',
                      color: '#ffffff',
                    }}
                  >
                    Link Discord
                  </button>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <SlideIn
        isOpen={slideInConfig.isOpen}
        title={slideInConfig.title}
        message={slideInConfig.message}
        onClose={() =>
          setSlideInConfig((prev) => ({ ...prev, isOpen: false }))
        }
        onConfirm={slideInConfig.onConfirm}
        showConfirm={slideInConfig.showConfirm}
        theme={theme} // Pass theme if SlideIn uses it
      />
    </div>
  );
};

export default Portal;
