// Documentation.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-lua';
import axios from 'axios';
import SlideIn from './portal/slidin'; // Ensure this path is correct


// Styles Object (using styles from Portal.js)
const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#f5f5f5',
  },
  documentationContent: {
    padding: '2rem',
    backgroundColor: '#fff',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
  },
  sidebar: {
    width: '250px',
    height: 'calc(100vh - 180px)',
    overflowY: 'auto',
    padding: '1rem',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
  },
  contentArea: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: '1rem',
  },
  codeBlock: {
    backgroundColor: '#2d2d2d',
    color: '#f8f8f2',
    padding: '1rem',
    borderRadius: '4px',
    marginBottom: '1rem',
    overflowX: 'auto',
  },
  codeDescription: {
    fontStyle: 'italic',
    color: '#888888',
    marginTop: '0.5rem',
    marginLeft: '1rem',
  },
  searchInput: {
    width: 'calc(100% - 40px)',
    padding: '0.5rem',
    marginBottom: '1rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
    outline: 'none',
  },
  sidebarItem: {
    color: '#4f47a7',
    marginBottom: '0.5rem',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'block',
  },
  sidebarSubItem: {
    color: '#4f47a7',
    marginBottom: '0.5rem',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'block',
    paddingLeft: '1rem',
  },
};

// Documentation Component
function Documentation() {
  const navigate = useNavigate();
  const [parsedContent, setParsedContent] = useState([]);
  const [loading, setLoading] = useState(false);

  // SlideIn state variables
  const [slideInConfig, setSlideInConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    showConfirm: false,
    onConfirm: null,
  });

  // Search state variable
  const [searchTerm, setSearchTerm] = useState('');

  // Markdown and Prism Configuration
  useEffect(() => {
    const handleMarkdownFetch = async () => {
      try {
        const response = await axios.get('https://z.ninovium.dev/docs.md');
        const text = response.data;
        const parsed = parseMarkdown(text);
        setParsedContent(parsed);
      } catch (error) {
        console.error('Failed to fetch markdown:', error);
        showAlert('Error', 'Failed to load documentation. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    handleMarkdownFetch();
  }, []);

  useEffect(() => {
    Prism.highlightAll(); // Ensure this runs after the component has rendered
  }, [parsedContent]);

  marked.setOptions({
    highlight: function (code, lang) {
      if (Prism.languages[lang]) {
        return Prism.highlight(code, Prism.languages[lang], lang);
      } else {
        return Prism.highlight(code, Prism.languages['lua'], 'lua');
      }
    },
    breaks: true, // Optional: Enables line breaks
  });

  const parseMarkdown = (markdown) => {
    const tokens = marked.lexer(markdown);
    let sections = [];
    let currentSection = null;
    let currentSubsection = null;

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (token.type === 'heading') {
        if (token.depth === 1) {
          if (currentSection) sections.push(currentSection);
          currentSection = { title: token.text, subsections: [] };
          currentSubsection = null;
        } else if (token.depth === 2 && currentSection) {
          if (currentSubsection) {
            currentSection.subsections.push(currentSubsection);
          }
          // Generate a unique id by combining section and subsection titles
          const subsectionId = `${currentSection.title}-${token.text}`
            .replace(/\s+/g, '-')
            .toLowerCase();
          currentSubsection = { title: token.text, id: subsectionId, content: [] };
        }
      } else if (currentSubsection) {
        // Check for code block followed by description
        if (token.type === 'code') {
          const codeBlock = { type: 'code', content: token, description: null };
          // Look ahead for description
          const nextToken = tokens[i + 1];
          if (
            nextToken &&
            nextToken.type === 'paragraph' &&
            nextToken.text.startsWith('Description:')
          ) {
            codeBlock.description = nextToken.text.replace(/^Description:\s*/, '');
            i++; // Skip the description token
          }
          currentSubsection.content.push(codeBlock);
        } else {
          currentSubsection.content.push(token);
        }
      }
    }

    // Push any remaining subsection and section
    if (currentSubsection) {
      currentSection.subsections.push(currentSubsection);
    }

    if (currentSection) {
      sections.push(currentSection);
    }

    return sections;
  };

  // Filtered content based on search term
  const filteredContent = parsedContent
    .map((section) => {
      const filteredSubsections = section.subsections.filter((subsection) =>
        subsection.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (
        section.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filteredSubsections.length > 0
      ) {
        return {
          ...section,
          subsections: filteredSubsections,
        };
      } else {
        return null;
      }
    })
    .filter((section) => section !== null);

  const renderContent = () => {
    return filteredContent.map((section) => (
      <div key={section.title}>
        <h1 style={{ color: '#4f47a7' }}>{section.title}</h1>
        {section.subsections.map((subsection) => (
          <div key={subsection.id}>
            <h2 id={subsection.id} style={{ color: '#4f47a7' }}>
              {subsection.title}
            </h2>
            <div>
              {subsection.content.map((token, index) => {
                if (token.type === 'code' && token.content) {
                  // Ensure token.content is defined and has necessary properties
                  const language = token.content.lang || 'lua';
                  const codeText = token.content.text || '';

                  if (codeText.length > 0) {
                    const highlightedCode = Prism.highlight(
                      codeText,
                      Prism.languages[language] || Prism.languages['lua'],
                      language
                    );

                    return (
                      <div key={index} style={{ marginBottom: '20px' }}>
                        <pre style={styles.codeBlock}>
                          <code
                            className={`language-${language}`}
                            dangerouslySetInnerHTML={{ __html: highlightedCode }}
                          />
                        </pre>
                        {token.description && (
                          <p style={styles.codeDescription}>{token.description}</p>
                        )}
                      </div>
                    );
                  } else {
                    return null;
                  }
                } else {
                  // For other token types, use marked to parse them
                  return (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: marked.parser([token]) }}
                    />
                  );
                }
              })}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  // SlideIn handling functions
  const showAlert = (title, message) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: false,
      onConfirm: null,
    });
  };

  const showConfirm = (title, message, action) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: true,
      onConfirm: action,
    });
  };

  // Handle navigation
  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) {
    return (
      <div style={{ ...styles.mainContainer }}>
        <div>Loading...</div>
        {/* Optionally, add a spinner or animated loader here */}
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <div style={styles.mainContainer}>
        <div style={styles.documentationContent}>
          {/* Sidebar */}
          <div style={styles.sidebar}>
            <h2 style={{ color: '#4f47a7', fontWeight: '500' }}>Documentation</h2>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={styles.searchInput}
            />
            {filteredContent.map((section) => (
              <div key={section.title}>
                <a
                  href={`#${section.title.replace(/\s+/g, '-').toLowerCase()}`}
                  style={styles.sidebarItem}
                >
                  {section.title}
                </a>
                {section.subsections.map((subsection) => (
                  <a
                    key={subsection.id}
                    href={`#${subsection.id}`}
                    style={styles.sidebarSubItem}
                  >
                    {subsection.title}
                  </a>
                ))}
              </div>
            ))}
          </div>

          {/* Documentation Content */}
          <div style={styles.contentArea}>{renderContent()}</div>
        </div>
      </div>

      {/* SlideIn for Alerts */}
      <SlideIn
        isOpen={slideInConfig.isOpen}
        title={slideInConfig.title}
        message={slideInConfig.message}
        onClose={() =>
          setSlideInConfig((prev) => ({ ...prev, isOpen: false }))
        }
        onConfirm={slideInConfig.onConfirm}
        showConfirm={slideInConfig.showConfirm}
      />
    </div>
  );
}

export default Documentation;
