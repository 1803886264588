// Tos.js
import React, { useEffect, useState } from 'react';
import { marked } from 'marked';
import axios from 'axios';
import SlideIn from './portal/slidin'; // Ensure this path is correct

// Styles Object (using styles from Portal.js)
const styles = {
  mainContainer: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#f5f5f5',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  tosContent: {
    padding: '2rem',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflowY: 'auto',
    flexGrow: 1,
  },
};

function Tos() {
  const [parsedContent, setParsedContent] = useState(''); // Changed to string
  const [loading, setLoading] = useState(true); // Initialize as true since we're loading

  // SlideIn state variables
  const [slideInConfig, setSlideInConfig] = useState({
    isOpen: false,
    title: '',
    message: '',
    showConfirm: false,
    onConfirm: null,
  });

  // Fetch and parse the TOS markdown
  useEffect(() => {
    const fetchTOS = async () => {
      try {
        const response = await axios.get('https://z.ninovium.dev/tos.md');
        const markdown = response.data;
        const html = marked.parse(markdown);
        setParsedContent(html);
      } catch (error) {
        showAlert('Error', 'Failed to load Terms of Service.');
      } finally {
        setLoading(false);
      }
    };

    fetchTOS();
  }, []);

  // SlideIn handling functions
  const showAlert = (title, message) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: false,
      onConfirm: null,
    });
  };

  const showConfirm = (title, message, action) => {
    setSlideInConfig({
      isOpen: true,
      title,
      message,
      showConfirm: true,
      onConfirm: action,
    });
  };

  if (loading) {
    return (
      <div style={{ ...styles.mainContainer }}>
        <div>Loading...</div>
        {/* Optionally, add a spinner or animated loader here */}
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <div style={styles.mainContainer}>
        {/* Render the parsed TOS content */}
        <div
          style={styles.tosContent}
          dangerouslySetInnerHTML={{ __html: parsedContent }}
        ></div>
      </div>

      {/* SlideIn for Alerts */}
      <SlideIn
        isOpen={slideInConfig.isOpen}
        title={slideInConfig.title}
        message={slideInConfig.message}
        onClose={() =>
          setSlideInConfig((prev) => ({ ...prev, isOpen: false }))
        }
        onConfirm={slideInConfig.onConfirm}
        showConfirm={slideInConfig.showConfirm}
      />
    </div>
  );
}

export default Tos;
