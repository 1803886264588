// devteam.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// SlideIn Component for Alerts and Confirmations
const SlideIn = ({ isOpen, title, message, onClose, onConfirm, showConfirm }) => {
  if (!isOpen) return null;

  return (
    <div style={overlayStyle} onClick={onClose}>
      <div style={slideInStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={titleStyle}>{title}</h2>
        <p style={messageStyle}>{message}</p>
        <div style={buttonContainerStyle}>
          {showConfirm && (
            <button style={confirmButtonStyle} onClick={onConfirm}>
              Confirm
            </button>
          )}
          <button style={closeButtonStyle} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles for SlideIn
const overlayStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  zIndex: 1000,
};

const slideInStyle = {
  background: '#fff',
  width: '100%',
  maxWidth: '500px',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  padding: '20px',
  boxShadow: '0 -2px 10px rgba(0,0,0,0.2)',
  animation: 'slideUp 0.3s ease-out forwards',
};

const titleStyle = {
  margin: '0 0 10px 0',
  fontSize: '20px',
  color: '#4f47a7',
};

const messageStyle = {
  fontSize: '16px',
  color: '#555',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '20px',
};

const confirmButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#7e56ef',
  color: '#fff',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

const closeButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ccc',
  color: '#333',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
};

function DevelopmentTeam() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [parsedContent, setParsedContent] = useState([]);
  const [loading, setLoading] = useState(false); // Initialize as true since we're loading

  const [isTokenValid, setIsTokenValid] = useState(false); // State to handle token validity

  // SlideIn state variables
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('Notification');

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmTitle, setConfirmTitle] = useState('Confirmation');
  const [confirmAction, setConfirmAction] = useState(null);

  // Animation state variables
  const [animationDirection, setAnimationDirection] = useState('forward');

  // Detect if the device is mobile (optional)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Token validation useEffect
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // Ping the validation endpoint
      fetch(`https://z.ninovium.dev/v1/auth/validate?token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Token is valid.") {
            setIsTokenValid(true); // Token is valid, show portal button
          } else {
            setIsTokenValid(false); // Token is invalid, show sign-in/log-in buttons
          }
        })
        .catch(() => {
          setIsTokenValid(false); // On error, show sign-in/log-in buttons
        });
    }
  }, []);

  const handleConfirm = () => {
    if (confirmAction) confirmAction();
    setIsConfirmOpen(false);
  };

  // Handle navigation with reverse animations
  const handleNavigation = (path) => {
    setAnimationDirection('reverse');

    // Duration should match your animation duration (0.5s in this case)
    setTimeout(() => {
      navigate(path);
    }, 500); // 500ms = 0.5s
  };

  // CSS Animations
  const animationStylesBlock = `
    /* Hide scrollbar */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Keyframe Animations */
    @keyframes slideUp {
      from { transform: translateY(100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideDown {
      from { transform: translateY(-100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }

    @keyframes slideInLeft {
      from { transform: translateX(-100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    @keyframes slideInRight {
      from { transform: translateX(100%); opacity: 0; }
      to { transform: translateX(0); opacity: 1; }
    }

    @keyframes slideOutLeft {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(100%); opacity: 0; }
    }

    @keyframes slideOutRight {
      from { transform: translateX(0); opacity: 1; }
      to { transform: translateX(100%); opacity: 0; }
    }

    @keyframes slideHeaderUp {
      from { transform: translateY(0); opacity: 1; }
      to { transform: translateY(-100%); opacity: 0; }
    }

    /* Animation Classes */
    .header-animated {
      animation: slideDown 0.5s ease-out forwards;
    }

    .header-reverse-animated {
      animation: slideHeaderUp 0.5s ease-out forwards;
    }

    .main-content-animated {
      animation: slideUp 0.5s ease-out forwards;
    }

    .main-content-reverse-animated {
      animation: slideOutLeft 0.5s ease-out forwards;
    }

    /* Loading Animation (Optional) */
    .loading-animation {
      font-size: 24px;
      color: #4f47a7;
      animation: slideUp 1s ease-out forwards;
    }
  `;

  if (loading) {
    return (
      <div style={{ ...appStyle, paddingTop: '100px' }}>
        <div className="loading-animation">Loading...</div>
        {/* Optionally, add a spinner or animated loader here */}
      </div>
    );
  }

  return (
    <div style={appStyle}>
      {/* Inject CSS Styles */}
      <style>{animationStylesBlock}</style>

      {/* Header */}
      <div className={animationDirection === 'forward' ? 'header-animated' : 'header-reverse-animated'} style={headerStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Left: Logo and Text */}
          <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '-30px' }}>
            <img
              src="https://z.ninovium.dev/Logo.svg"
              alt="Ninovium Logo"
              style={{ height: '25px', marginTop: '-3px' }}
            />
            <span style={{ marginLeft: '7px', fontSize: '26px', fontWeight: '500', color: '#4f47a7' }}>
              Ninovium
            </span>
          </div>

          {/* Middle: Links (Home, Documentation, Support) */}
          <div
            style={{
              position: 'absolute', // Use absolute positioning
              left: '50%', // Center horizontally
              transform: 'translateX(-50%)', // Offset by 50% of its own width to center properly
              display: 'flex', // Ensure the links are still flexed
              justifyContent: 'center',
            }}
          >
            <a
              onMouseEnter={e => (e.target.style.color = '#6a5ecf')}
              onMouseLeave={e => (e.target.style.color = '#4f47a7')}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/');
              }}
              style={linkStyle}
            >
              Home
            </a>
            {!isMobile && (
              <a
                onMouseEnter={e => (e.target.style.color = '#6a5ecf')}
                onMouseLeave={e => (e.target.style.color = '#4f47a7')}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation('/documentation');
                }}
                style={linkStyle}
              >
                Documentation
              </a>
            )}
            <a
              onMouseEnter={e => (e.target.style.color = '#6a5ecf')}
              onMouseLeave={e => (e.target.style.color = '#4f47a7')}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation('/support');
              }}
              style={linkStyle}
            >
              Support
            </a>
          </div>

          {/* Right: Sign In/Sign Up or Portal Button based on token validity */}
          <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
            {isTokenValid ? (
              <a
                onClick={() => handleNavigation('/portal')}
                style={{
                  ...linkStyle,
                  ...buttonStyle3,
                  borderRadius: '20px',
                  marginRight: '-40px',
                }}
              >
                Portal
              </a>
            ) : (
              <>
                <a
                  onClick={() => handleNavigation('/register')}
                  style={{
                    ...linkStyle,
                    ...buttonStyle3,
                    borderRadius: '20px 0 0 20px',
                    marginRight: '-20px',
                  }}
                >
                  Sign In
                </a>
                <a
                  onClick={() => handleNavigation('/register')}
                  style={{
                    ...linkStyle,
                    ...buttonStyle3,
                    borderRadius: '0 20px 20px 0',
                    marginRight: '-40px',
                  }}
                >
                  Sign Up
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={animationDirection === 'forward' ? 'main-content-animated' : 'main-content-reverse-animated'} style={mainContentStyle}>
      </div>

      {/* SlideIn for Alerts */}
      <SlideIn
        isOpen={isAlertOpen}
        title={alertTitle}
        message={alertMessage}
        onClose={() => setIsAlertOpen(false)}
        showConfirm={false}
      />

      {/* SlideIn for Confirmations */}
      <SlideIn
        isOpen={isConfirmOpen}
        title={confirmTitle}
        message={confirmMessage}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={handleConfirm}
        showConfirm={true}
      />
    </div>
  );
}

export default DevelopmentTeam;

const appStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflowY: 'hidden',
  minHeight: '100vh',
  width: '100vw',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  background: "url('https://z.ninovium.dev/bg-test.jpg')",
  backgroundSize: 'cover',
  fontFamily: 'Baloo 2, sans-serif',
  backdropFilter: 'blur(10px)',
  color: '#4a4a4a',
  overflowZ: 'hidden'
};

const headerStyle = {
  background: 'rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(40px)',
  WebkitBackdropFilter: 'blur(40px)',
  borderRadius: '0 0 20px 20px',
  borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 50px',
  zIndex: 1001,
  position: 'absolute',
  top: 0,
  width: 'calc(100vw - 160px)',
  margin: '0 40px 0 40px',
  fontSize: '18px',
  color: '#4f47a7',
  fontFamily: 'Baloo 2',
};

const linkStyle = {
  color: '#4f47a7',
  textDecoration: 'none',
  margin: '0 20px',
  fontFamily: 'Baloo 2, sans-serif',
  fontSize: '18px',
  cursor: 'pointer',
  transition: 'color 0.3s ease-in-out',
};

const buttonStyle3 = {
  padding: '5px 30px',
  border: 'none',
  background: 'linear-gradient(90deg, #9c7ef3, #7e56ef)', // Gradient background
  color: 'white',
  fontSize: '18px',
  fontFamily: 'Baloo 2, sans-serif',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease-in-out',
  borderRadius: '20px',
};

const mainContentStyle = {
  flex: 1,
  padding: '30px',
  marginTop: '80px', // To account for fixed header
  marginLeft: '50px',
  marginRight: '50px',
  borderRadius: '20px 20px 0 0',
  background: 'rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(40px)',
  WebkitBackdropFilter: 'blur(40px)',
  borderBottom: '1px solid rgba(79, 71, 167, 0.3)',
  boxShadow: '0 2px 10px rgba(79, 71, 167, 0.1)',
  borderTop: '1px solid rgba(79, 71, 167, 0.3)',
  width: 'calc(100vw - 120px)',
  maxHeight: 'calc(100vh - 143px)',
};