import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

export default function Meow() {
  return (
    <div
      style={{
        fontFamily: '"Baloo 2", sans-serif',
        backgroundColor: '#f5f5f5',
        color: '#333',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: '1.2', // Fix to prevent extra vertical padding from Baloo 2
      }}
    >
      <Header />
      <HeroSection />
      <ImageSection />
    </div>
  );
}

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
        setMenuOpen(false); // Close menu when resizing to larger view
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header
      style={{
        width: '100%',
        padding: '1rem 0',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '90%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '1200px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', height: '50px' }}>
          <img
            src="https://z.ninovium.dev/Logo.svg"
            alt="Ninovium Logo"
            style={{ height: '30px', marginRight: '10px' }}
          />
          <h1
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: '#4f47a7',
              lineHeight: '1', // Fix to prevent extra vertical padding from Baloo 2
            }}
          >
            Ninovium
          </h1>
        </div>
        {(isMobileView && (
          <>
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '24px',
              }}
            >
              {menuOpen ? <FaTimes /> : <FaBars />}
            </button>
            <nav
              style={{
                display: menuOpen ? 'flex' : 'none',
                flexDirection: 'column',
                gap: '1rem',
                position: 'absolute',
                top: '70px',
                right: '10px',
                backgroundColor: '#f5f5f5',
                padding: '1rem',
                boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                zIndex: 1000,
              }}
            >
              <a href="#home" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
                Home
              </a>
              <a href="#documentation" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
                Documentation
              </a>
              <a href="#support" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
                Support
              </a>
              <a href="#terms" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
                Terms Of Service
              </a>
            </nav>
          </>
        )) || (
          <nav style={{ display: 'flex', gap: '1rem' }}>
            <a href="#home" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
              Home
            </a>
            <a href="#documentation" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
              Documentation
            </a>
            <a href="#support" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
              Support
            </a>
            <a href="#terms" style={{ textDecoration: 'none', color: '#333', fontSize: '1.1rem' }}>
              Terms Of Service
            </a>
          </nav>
        )}
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <button
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#705697',
              color: '#f1f1f1',
              border: 'none',
              borderRadius: '4px',
              fontSize: '16px',
              cursor: 'pointer',
              fontFamily: '"Baloo 2", sans-serif',
              lineHeight: '1', // Fix to prevent extra vertical padding from Baloo 2
            }}
          >
            Sign In
          </button>
          <button
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#4e3c69',
              color: '#f1f1f1',
              border: 'none',
              borderRadius: '4px',
              fontSize: '16px',
              cursor: 'pointer',
              fontFamily: '"Baloo 2", sans-serif',
              lineHeight: '1', // Fix to prevent extra vertical padding from Baloo 2
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    </header>
  );
};

const HeroSection = () => (
  <section
    style={{
      textAlign: 'center',
      padding: '4rem 2rem',
    }}
  >
    <h2
      style={{
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        lineHeight: '1.1', // Fix to prevent extra vertical padding from Baloo 2
      }}
    >
      Breaking boundaries that were never set.
    </h2>
    <p
      style={{
        fontSize: '1.2rem',
        marginBottom: '2rem',
        color: '#555',
        lineHeight: '1.4', // Adjusted line-height for better readability
      }}
    >
      Ninovium's powerful Lua debugging utility allows you to write and execute code like never before.
    </p>
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        justifyContent: 'center',
        marginBottom: '-40px',
        flexWrap: 'wrap',
      }}
    >
      <button
        style={{
          padding: '0.75rem 1.5rem',
          backgroundColor: '#705697',
          color: '#f1f1f1',
          border: 'none',
          borderRadius: '4px',
          fontSize: '18px',
          cursor: 'pointer',
          fontFamily: '"Baloo 2", sans-serif',
          lineHeight: '1', // Fix to prevent extra vertical padding from Baloo 2
        }}
      >
        Get Started
      </button>
      <button
        style={{
          padding: '0.75rem 1.5rem',
          backgroundColor: '#705697',
          color: '#f1f1f1',
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '18px',
          cursor: 'pointer',
          fontFamily: '"Baloo 2", sans-serif',
          lineHeight: '1', // Fix to prevent extra vertical padding from Baloo 2
        }}
      >
        Join The Community
      </button>
    </div>
  </section>
);

const ImageSection = () => (
  <section
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '2rem 0',
      boxSizing: 'border-box',
    }}
  >
    <img
      src="https://z.ninovium.dev/exc.png"
      alt="Ninovium IDE Example"
      style={{
        maxWidth: '80%',
        height: 'auto',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
      }}
    />
  </section>
);
