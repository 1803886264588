// SlideIn.js
import React, { useState, useEffect } from 'react';

// Reading Themes from LocalStorage
const themes = {
  light: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    textColor: '#333',
    titleColor: '#4f47a7',
    buttonBackgroundColor: '#705697',
    buttonHoverBackgroundColor: '#5a4485',
    captchaBorderColor: '#ccc',
    spinnerBorderColor: '#3f2d7e',
  },
  dark: {
    backgroundColor: '#3b4252',
    textColor: '#d8dee9',
    titleColor: '#9673d3',
    buttonBackgroundColor: '#9673d3',
    buttonHoverBackgroundColor: '#6b5f93',
    captchaBorderColor: '#4c566a',
    spinnerBorderColor: '#88c0d0',
  },
};

// SlideIn Component
const SlideIn = ({
  isOpen,
  type = 'alert', // 'alert' or 'captcha'
  title,
  message,
  onClose,
  onConfirm,
  onCaptchaSubmit,
  captchaSvg,
  captchaToken,
}) => {
  const [visible, setVisible] = useState(isOpen);
  const [animateOut, setAnimateOut] = useState(false);
  const [captchaInput, setCaptchaInput] = useState('');

  // Determine the current theme from localStorage
  const currentTheme = localStorage.getItem('theme') || 'light';
  const theme = themes[currentTheme];

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setAnimateOut(false);
    } else if (visible) {
      // Start exit animation
      setAnimateOut(true);
      // Wait for the animation to finish before unmounting
      const timer = setTimeout(() => setVisible(false), 300); // Match animation duration
      return () => clearTimeout(timer);
    }
  }, [isOpen, visible]);

  const handleCaptchaSubmit = (e) => {
    e.preventDefault();
    if (onCaptchaSubmit && captchaInput.trim() !== '') {
      onCaptchaSubmit(captchaInput, captchaToken);
      setCaptchaInput('');
    }
  };

  if (!visible) return null;

  return (
    <>
      {/* Inject CSS Styles */}
      <style>
        {`
          /* SlideUp Keyframes Animation */
          @keyframes slideUp {
            from {
              transform: translateY(100%);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }

          /* SlideDown Keyframes Animation */
          @keyframes slideDown {
            from {
              transform: translateY(0);
              opacity: 1;
            }
            to {
              transform: translateY(100%);
              opacity: 0;
            }
          }

          /* FadeIn Keyframes Animation */
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          /* Overlay Styles */
          .slidein-overlay {
            position: fixed;
            bottom: 3vh;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            animation: fadeIn 0.3s ease-in-out forwards;
          }

          /* SlideIn Container Styles */
          .slidein-container {
            background: ${theme.backgroundColor};
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            width: 90%;
            max-width: 400px;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            animation-name: ${animateOut ? 'slideDown' : 'slideUp'};
          }

          /* Text Container Styles */
          .slidein-text-container {
            margin-bottom: 20px;
            text-align: center;
            overflow: hidden;
            white-space: normal;
          }

          /* Title Styles */
          .slidein-title {
            font-size: 1.75rem;
            font-weight: bold;
            color: ${theme.titleColor};
            margin-bottom: 15px;
            font-family: 'Baloo 2', sans-serif;
          }

          /* Message Styles */
          .slidein-message {
            font-size: 1.1rem;
            color: ${theme.textColor};
            font-family: 'Baloo 2', sans-serif;
            line-height: 1.5;
          }

          /* Button Container Styles */
          .slidein-button-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            justify-content: center;
          }

          /* Confirm and Close Button Styles */
          .slidein-button {
            padding: 0.5rem 1rem;
            background-color: ${theme.buttonBackgroundColor};
            color: #f1f1f1;
            border: none;
            border-radius: 4px;
            font-size: 16px;
            cursor: pointer;
            font-family: 'Baloo 2', sans-serif;
            line-height: 1;
            transition: background-color 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 120px;
          }

          .slidein-button:hover {
            background-color: ${theme.buttonHoverBackgroundColor};
          }

          /* CAPTCHA Input Styles */
          .slidein-captcha-input {
            width: calc(100% - 20px);
            padding: 10px;
            border-radius: 8px;
            border: 1px solid ${theme.captchaBorderColor};
            font-size: 16px;
            outline: none;
            margin-bottom: 10px;
          }

          /* CAPTCHA Image Styles */
          .slidein-captcha-image {
            width: 100%;
            border-radius: 8px;
            margin-bottom: 10px;
          }

          /* Spinner Animation */
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          .spinner {
            border: 4px solid #f3f3f3;
            border-top: 4px solid ${theme.spinnerBorderColor};
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 1s linear infinite;
            margin-right: 10px;
          }
        `}
      </style>

      {/* Overlay */}
      <div className="slidein-overlay" onClick={onClose}>
        {/* SlideIn Container */}
        <div
          className="slidein-container"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Conditional Rendering Based on Type */}
          {type === 'captcha' ? (
            <>
              <div className="slidein-text-container">
                <span className="slidein-message">Please complete the CAPTCHA:</span>
              </div>
              {captchaSvg && (
                <img
                  src={captchaSvg}
                  alt="Captcha"
                  className="slidein-captcha-image"
                />
              )}
              <form onSubmit={handleCaptchaSubmit} style={{ width: '100%' }}>
                <input
                  type="text"
                  placeholder="Enter CAPTCHA"
                  value={captchaInput}
                  onChange={(e) => setCaptchaInput(e.target.value)}
                  className="slidein-captcha-input"
                  required
                />
                <div className="slidein-button-container">
                  <button type="submit" className="slidein-button">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="slidein-button"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              {/* Text Container */}
              <div className="slidein-text-container">
                {/*{title && <div className="slidein-title">{title}</div>}*/}
                <span className="slidein-message">{message}</span>
              </div>

              {/* Button Container */}
              <div className="slidein-button-container">
                {onConfirm && (
                  <button
                    className="slidein-button"
                    onClick={onConfirm}
                  >
                    Confirm
                  </button>
                )}
                <button
                  className="slidein-button"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SlideIn;
